html, body {
  background: #1c2224;
  padding: 0;
  font-family: Roboto, sans-serif;
}

* {
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
}

.main {
  gap: 1em;
  padding: 2em;
  display: flex;
}

.btn {
  color: #ddd;
  cursor: default;
  background: #434a4d;
  border-radius: .2em;
  padding: .5em 2em;
  transition: background .2s;
}

.btn:hover {
  background: #2b2f30;
}
/*# sourceMappingURL=banktransactions.d3d13b21.css.map */
