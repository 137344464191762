html, body
	padding: 0
	background: #1c2224
	font-family: 'Roboto', sans-serif

*
	box-sizing: border-box

a
	color: inherit
	text-decoration: none


.main
	display: flex
	padding: 2em
	gap: 1em

.btn
	background: #434a4d
	color: #ddd
	border-radius: 0.2em
	padding: 0.5em 2em
	cursor: default
	transition: 0.2s background
	&:hover
		background: #2b2f30